h1, h2, h3 {
  font-family: Georgia, serif;

}
h4,h5,h6{
  margin-bottom: .25rem;
}
 h1{
    font-size: 24px;
    font-weight: normal;
    color:#192039;
    margin-bottom: 1rem;
   @media only screen and (min-width: $md){
     text-align: left;
     font-size: 30px;
   }

}
 h2{
    font-size: 22px;
    font-weight: normal;
    color:#192039;
    margin-bottom: .25rem;
}

 header > h2 > p{
   @media only screen and (max-width: $md){
       text-align: center;
   }
 }
 h3 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: .75rem;
 }


.news h3{
  font-family: Verdana, serif;
  font-size: 18px;
  padding: 10px 0;
  a{
    color:#333;
  }
}

.more{}

.ipbContent h6{
  font-size: 14px;
}

.ce-div:not(.frame-type-div .ce-div ){
  margin-bottom: 20px !important;
}
.frame-type-div .ce-div{
  margin-bottom: 4px;
}

.cell h3{
  margin-bottom: .75rem;
}

.frame-type-multicolumn{
  .blog-item{
    margin-bottom: 1rem;
  }
}

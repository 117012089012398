
.carousel {
  max-width: 1220px;
  width: 100%;
  height: 400px;
  @media only screen and (min-width: $md){
    height: 420px;
  }
  position: relative;
  z-index: 1;
}

.carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: 400ms opacity ease-in-out;
  transition-delay: 200ms;
}

.slide {
  img {
    display: block;
    width: 100%;
    height: 400px!important;
    object-fit: cover;
    object-position: center;
  }
}

.slide[data-active] {
  opacity: 1;
  z-index: 1;
  transition-delay: 0ms;
}

.carousel-button {
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  width: 3rem;
  height: 3rem;
  color: rgba(255, 255, 255, 0.5);
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;
  padding: 0 .5rem;
  background-color: rgba(0, 0, 0, .1);
}

.carousel-button:hover,
.carousel-button:focus {
  color: white;
  background-color: rgba(0, 0, 0, .2);
}

.carousel-button:focus {
  outline: 1px solid #222;
}

.carousel-button.prev {
  left: 1rem;
}

.carousel-button.next {
  right: 1rem;
}


.carousel-dots {
  position: absolute;
  bottom: 0;
  @media only screen and (max-width: $md){
    bottom: 10px;
  }
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  z-index: 1;
}

.carousel-dots li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-dots li.active-dot {
  background-color: rgba(0, 0, 0, .75);
}


.slide{
  span{
    box-sizing: border-box;
    width: auto;
    height: auto;
    z-index: 1;
    background: rgb(0, 0, 0, .45);
    color: white;
    position: absolute;
    top:45%;
    transform: translateY(-45%);
    left:1rem;
    right: 1rem;
    word-break: break-word;
    @media only screen and (min-width: $md) {
      top: 50%;
      transform: translateY(-50%);
      left:50px;
      right: auto;
    }

    h3{
      font-size: 30px;
      line-height: 36px;
      padding: 1rem 1rem .25rem 1rem ;
    }
    i{
      font-size: 16px;
    }
    p{
      padding-bottom: .25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      &:last-child{
        padding-bottom: 1rem;
      }
    }
  }
}
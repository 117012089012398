@import "var";

.breadcrumb{
  list-style: none;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  @media only screen and (min-width: $lg){
    margin-left: 0;
  }
  li{
    padding-right: 1rem;
  }
}


@media only screen and (min-width: $lg) {
  .breadcrumb{
    display: flex;
    flex-wrap: wrap;
    li{

    }
  }
}
// clearfix
.clear{
   clear:both!important;
   overflow:hidden!important;
   line-height:0!important;
   height:0!important;
   *zoom: 1;
   &:before,&:after{
      content:""!important;display:table!important;
   }
   &:after{
      clear:both!important;
   }
}

main{
   p:not(header p, .categorie p, .news .footer p, aside p, .searchIpbResultRow p, .image-caption p, .fancyRight p){
      line-height: 18px;
      margin-bottom: 1rem;
   }

   @media only screen and (min-width: $lg){
      margin-bottom: 2rem;
   }
}
.hiddenEl{
   display: none;
   visibility: hidden;
   opacity: 0;
}

aside{
   width: 100%;
   float: none;
   @media only screen and (min-width: $lg){
      width: 28%;
      float: left;
   }

   .entry{
      overflow: hidden;
      box-sizing: border-box;

      .frame-type-text{
        padding: 1rem ;
         margin-bottom: 1.5rem;
         background: #f1f1f1;
         &:after{
            content: '111';
            display: table;
            clear: both;
            font-size: 0;
         }
         img{
            float: left;
            margin-right: 10px;
         }
      }

   }
}

.typo3-messages{
   li{
      list-style: none;
   }
}
.ipbContent {
   color: #283239;
   font-size: 13px;
   @media only screen and (max-width: $md){
      padding: 2rem 1rem;
     // margin-bottom: 2rem;
   }
   ul:not(.pagination,.moreLinksContainer,.listCol1,.jobs-wrap ul, ul.nav-tabs, ul.typo3-messages){
      margin-left: 1rem;
      margin-bottom: 1rem;
      li{
         list-style-type: square;
         margin-bottom: 4px;
      }
   }
}





#tabbar-drop-down,.moreLinksContainer ul,.col-md-9 ul{margin-left: 0!important;}

.ipbContent{
   background: white;
   width: 100%;
   @media only screen and (max-width: $md){
      margin-bottom: 2rem;
   }
   .frame-layout-4{
      background: #ededed;
      padding: 15px;
      margin-bottom: 20px;
      display: block;
      &:first-child{
         margin-top: 15px!important;
      }
   }
}

.employeeDetail {
   background: #D8D8D8;
   padding: 15px 20px;
   width: 100%!important;
   max-width: 800px!important;
   //margin-bottom: 2rem;
   transition: all 1s ease-out;
}
.container{
   width: 100%;
   max-width: 1120px;
   box-sizing: border-box;
   margin: auto;
   @media only screen and (max-width: $lg){
      padding-right: 1rem;
      padding-left: 1rem;
   }
}

.gridContainer:not(.search .gridContainer ) {
   display: flex;
   flex-direction: column-reverse;
   @media only screen and (min-width: $lg){
     display: inline;

   }
}

.page363 .gridContainer {
   display: flex;
   flex-direction: column!important;
   @media only screen and (min-width: $lg){
      display: inline;
   }
}


.aside-nav-wrap {display: none;visibility: hidden;width: 100%;}
.ipbContent:not(.page1){float: none}

@media only screen and (min-width: $lg) {
   .aside-nav-wrap {display: block;visibility: visible}

   .ipbContent:not(.employees) {
      background: white;
      padding: 30px 30px 60px 30px;
      float: right;
      width: 70%;}

   .home > .ipbContent{
      width: 100%!important;
      background: white;
      padding: 1.5rem 0;
   }

   .error-page > .ipbContent{
      width: 100%!important;
      background: white;
      padding: 1.5rem 0;
   }

   .employees > .ipbContent{
      width: 100%!important;
      background: white;
      padding: 1.5rem 0;
   }

 body.home main div.container div.gridContainer div.ipbContent,
 body.employees main div.container div.gridContainer div.ipbContent
 {
    width: 100%!important;
    background: transparent;
    padding: 0 0;
    .lastModified,.print{
       display: none;
    }
 }


body.error-page main div.container div.gridContainer div.ipbContent
{
   width: 100%!important;
   background: #FFF;
   padding: 2rem;
   .lastModified,.print{
      display: none;
      opacity: 0;
      visibility: hidden;
   }
}


   .gridColumns75x25{
   display: grid;
   grid-template-columns: 2fr 1fr;
   gap:2rem;
}
}


.home .fc-unthemed {
   @media only screen and (min-width: $md){
      display: grid;
      grid-template-columns: auto 350px;
      //grid-template-rows: auto;
      grid-template-areas:
    "fc-header-toolbar fc-header-toolbar "
    "fc-view-container categorie_selector";
   }

}

.fc-header-toolbar{
   grid-area: fc-header-toolbar;
}
.fc-view-container{
   grid-area: fc-view-container;
}
.categorie_selector{
   grid-area:categorie_selector ;
}

.page1  #categorie_selector {
   -moz-column-count: 1;
   //-moz-column-gap: 1;
   -webkit-column-count: 1;
   -webkit-column-gap: 0;
   column-count: 1;
  // column-gap: 1;
   background: white;
   padding: 1rem;
}


.frame-linkAllePub{
   .ce-textpic{
      .ce-bodytext{
         p{
            a{
               font-weight: 600;
               font-size: 15px;
               @media only screen and (max-width: $md){
                  text-align: center;
                  display: block;
               }
            }
         }
      }

   }
   margin-bottom: 2rem;
   display: block;
   padding-left: 10px;
}

.frame-buttonRightBottomHomePage{
   margin-bottom: 1rem;
   display: block;
   padding-left: 10px;
   .ce-textpic{
      .ce-bodytext{
         p{
            a{
               font-size: 20px;
               background: #CBCBCB;
               margin-bottom: 1rem;
               padding: 10px;
               display: block;
               font-family: Georgia, Times, serif;
               text-align: center;
               border-radius: .75rem;
            }
         }
      }

   }
}

//owerrid

@media only screen and (max-width: 390px){
.fc-header-toolbar .fc-button-group button,.fc-today-button,.fc button{font-size: 11px!important;}
}

#categorie_selector{
   word-break: break-word;
}

.employeeDetail{
   width: 100%;
   max-width: 390px;
   @media only screen and (max-width: 390px){
      max-width: 300px;
   }
}
.ipbContent {
   .employeeDetail{
      margin-bottom: 1.5rem;
   }
}
.entry .entry{
   background: #f1f1f1;
   display: grid;
   grid-template-columns: 30% 70%;
   column-gap: .75rem;
   margin-bottom: 1rem;
   div:nth-child(2){
      padding-right: 1rem;
      padding-top: .5rem;
      word-break: break-word;
      line-height: 1.3;
   }
   .person-info{
      color: #7F8B97;
   }
}

.entry-drop-top-wrap img{
   width: 250px;
   height: 150px;
   object-fit: cover;
}

@media only screen and (max-width: $md){
   .breadcrumb{
      position: relative;
      width: 100%;
      height: auto;
      top:80px;
      padding: 0 0 1rem 0;
         li{
            display: inline-flex;
         }
   }
   .entry-drop{
      margin-bottom: 1rem!important;
   }
   .entry-drop-top-wrap img{
      width: 100%;
      float: none!important;
   }
   .entry-drop-bottom-wrap,.entry-drop-top-wrap-info{
      width: 100%;
      max-width: 500px;
      float: none!important;
      padding: 1rem;
      h2{
         a{
            font-size: 22px!important;
            margin-top: 2rem;
         }

      }
   }
   #tabbar-drop-down{
      width: 100%!important;
      max-width: $md;
   }
   #searchfield {
      width: 80%!important;
      margin-left: 1rem;
   }

}

.frame-type-felogin_login{
   display: inline;
   form{
      fieldset{
         legend{
            margin-bottom: 1rem;
         }
         border:none;
         label{
            width: 100%;
            max-width: 300px;
            display: grid;
            input{
               margin-bottom: 1rem;
               padding: .5rem;
            }
         }
      div:nth-child(4){
         margin-bottom: 2rem;
         input[type=submit]{
            padding: .5rem;
            }
         }
      }
   }
}

@media only screen and (max-width: $md){
   .ce-right .ce-gallery {
      float: none;
   }
}


// avonis_intranet

div.oneRow > label{
   @media only screen and (max-width: $md){
      word-break:break-word;
      font-size: 13px;
      width: 100%;
      max-width: 300px;
   }
}

.ipbEditForm > input[type=submit] {
   padding: .5rem;
   cursor: pointer;
   @media only screen and (max-width: $md){
      margin-top: 15px;
   }
   &:focus{
      background: #899e00;
      border: none;
      border-radius: 5px;
      color: white;
   }
}
.ipbEditForm > table {
   margin-bottom: 40px;
}

#tabbar-drop-down {
   width: 100% !important;
}

#tabbar-drop-down #last-tab a {
   width: auto!important;
   border: none;
}
@media only screen and (max-width: $lg){
   #tabbar-drop-down li a{
     border-right: none!important;
   }

   #projectsSub{
      width: 100%!important;
      max-width: 692px!important;
   }
}
.lastModified{
   top:0!important;
}

@media only screen and (max-width: $md){
   #tabbar-drop-down li a {
      width: auto!important;
      border: none;
      font-size: 12px!important;
      border-right: none!important;
   }
   #tabbar-drop-down{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      padding-bottom: 1rem;
   }

   #tabbar-drop-down li{
      width: auto!important;
      float: none!important;
     padding: 0 5px;
   }
   #tabbar-drop-down li a{
      padding: 0 8px;
      border-radius: 2px;
   }

   .employeeListWrap .employeeListHeader h2{
      width: 100%!important;
   }
}

.print{
   text-align: right;
}

.printIpb{
   display: flex;
   flex-direction: row;
   align-items:center;
   justify-content:end;
   position: relative;
   top:1.5rem;
   &:visited,&:focus{
      text-decoration: none!important;
   }
   &:hover{
      opacity: .75;
   }

}
.printIpb:visited{
   text-decoration: none!important;
}

.tabele_organigramm{
   width: 100%!important;
}

@media only screen and (max-width: $md) {
   .container
   {
      padding-right: 0;
      padding-left: 0;
   }
}

.ce-gallery{
   .ce-row{
      .ce-column{
         figure.image{
            img{
               border: 1px solid #CBCBCB;
               padding: 2px 2px;
            }
            .image-caption{
            }
         }
      }
   }
}

.ce-gallery figcaption {
   display: table-caption;
   caption-side: bottom;
   font-size: 11px;
   padding: 8px;
   border: 1px #CBCBCB solid;
   border-top-width: 1px;
   border-top-style: solid;
   border-top-color: rgb(203, 203, 203);
   border-top: none;
   background: #fff;
   position: relative !important;
   top: -1px;
   z-index: 5;

}


.ce-left{

}

.frame-layout-5{
   background: #ededed;
   padding: 15px;
   margin-bottom: 20px;
   min-height: auto;
   h3:not(.ce-headline-big_distance,.ce-headline-big_distance_subheader, .ce-headline-medium_distance,.ce-headline-medium_distance_subheader,
   .ce-headline-small_distance, .ce-headline-small_distance_subheader,.ce-headline-distance_subheader){
      margin-bottom: 0!important;
   }
}


.coverArt{
   .frame-layout-5,.frame-layout-4{
      img.image-embed-item{
         width: auto;
         height: 140px!important;
      }
   }
   #c12052{
     // margin-bottom: 0!important;
   }
}
/*
.frame-space-after-extra-small{
   margin-bottom: 0!important;
}
.frame-space-after-small{
   margin-bottom: .5rem!important;
}
.frame-space-after-medium{
   margin-bottom: 2rem!important;
}

.frame-space-after-large{
   margin-bottom: 3rem!important;
}*/

.frame-print_products{
   img.image-embed-item{
      width: auto;
      height: 260px;
      border-color:transparent;
   }
   .ce-gallery .ce-row .ce-column figure.image img {
      border: none;
      padding: 2px 2px;
   }
}

/*
.frame-type-text p, .frame-type-textpic p:not(.image-caption p, header h1 p,header h2 p),.frame-type-list {
   margin-bottom: 1rem;
}
.frame-type-list{
   .grid{
      margin-bottom: 2rem;
   }
}*/

.entry .person-info-wrap h3 {
   font-family: serif;
   font-size: 20px;
   color: #0C66AD;
   font-weight: normal;
   margin-bottom: 1px;
}
.frame-bottomLine, .frame-layout-3{
   border-bottom: 1px #CBCBCB solid;
   margin-bottom: 25px;
}

.hiddenForMobile{
   display: none;
   @media only screen and (min-width: $lg){
      display: block;
   }
}

.frame-greenBorder {
   border: solid 2px #899e01;
   height: auto;
   @media only screen and (min-width: $md){
      height: 210px;
   }
   padding: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;

}
.green-border{
   border: solid 2px #899e01!important;
   .image-caption{
     border:none;
   }
   @media only screen and (max-width: $lg){
     margin-bottom: 1rem;
   }
}
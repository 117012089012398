@import "base/include";

$sm:576px;
$md:768px;
$lg:992px;
$xl:1200px;
$xxl:1400px;

pre{width: 100%}


.ce-gallery {
  .ce-row{
    @media only screen and (min-width: $lg){
      display: flex;
      align-items: flex-start;
      align-content: stretch;
      //max-width: 720px;
    }
    .ce-column{
      .image{
        .image-embed-item{
        }
      }
    }
  }
}



.moreLinksContainer li{
  list-style: none!important;
}
.ce-border{
  .image{
    border: none!important;
  }
  figure.image img {
    border: 1px solid transparent!important;
    padding: 0!important;
  }

}



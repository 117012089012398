@import "var";

footer{
  background: #ededed;
  min-height: 300px;
  width: 100%;

}
.bottomLogoSection{
  background: #19213a;
  color:#fff;
}
.footerBg{
  background: #19213a;
}

.sitemapLinkLevel1{
  padding-bottom: 1rem;
  >a{
    font-weight: 600;
    font-size: 16px;
  }
}
.menuSitemap{
  width: 100%;
  display: inline;
  @media only screen and (min-width: $lg){
    display: grid;

  }
  background: #222942;
  grid-template-columns: repeat(5,auto);
  gap: 20px;
  li{
    padding: 5px 0;
    margin: 0!important;
    list-style: none;
    &::marker{
      height: 0;
      padding: 0;
      margin: 0;
      display: none;
    }
    a{
      color:#a7aab4;
      padding: 0!important;
      margin: 0!important;
      ul{
        li{
          list-style: none!important;
          &::marker{
            height: 0;
            padding: 0;
            margin: 0;
            display: none;
          }
        }
      }
    }
  }
}

.divSitemap {
  display: none;
  background: #222942;
  width: 100vw;
  height: auto;
  position: relative;
  top:0;
  z-index: 1;
  padding-bottom: 50px;
  padding-top: 20px;
  @media only screen and (max-width: $lg){
   padding-left: 1rem;
  }
}



@media only screen and (min-width: $lg) {
  .menuBottom{
    display: flex!important;
  }
}

.menuBottom{
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
    li{
      list-style: none;
      padding-right: 1rem;
      @media only screen and (max-width: $md) {
        background: #D8D8D8;
        padding: 2px 6px 4px;
        margin: 5px;
      }
    }
  @media only screen and (min-width: $lg) {
    display: flex!important;
  }
}



.logosPartner{
  padding: 1rem;
  list-style: none;
  display: grid;
  grid-template-columns: auto auto;
  min-height: 100px;
  align-content: space-between;
  align-items: center;
  li{
    a{
      &:first-child{
        padding-left: 0!important;
      }
      img{
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
    button{
      background: transparent;
      color: white;
      border: none;
      font-size: 13px;
      font-family: Verdana, Arial, Helvetica, sans-serif;
      padding-left: 1rem;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: $lg) {
    display: flex;
    align-content: space-between;
    align-items: center;
    li{
      a{
        &:first-child{
          padding-left: 0!important;
        }
        img{
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          border-right: 1px #7c8497 solid;

        }
      }
      button{
        background: transparent;
        color: white;
        border: none;
        font-size: 13px;
        font-family: Verdana, Arial, Helvetica, sans-serif;
        padding-left: 1rem;
        cursor: pointer;
      }
    }

  }
}

.hidButt, .showButt{
  &:before{
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 22px;
    margin-right:6px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.hidButt, .showButt{
  &:before{
    content: "\f467";
  }
}

.showButt{
  &:before{
    content: "\f506";
  }
}
$sm:576px;
$md:768px;
$lg:992px;
$xl:1200px;
$xxl:1400px;

$black: #192139;
$green: #899e00;

@keyframes fadein {
  from { top: -10rem; }
  to   { top: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { top: -10rem; }
  to   { top: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { top: -10rem; }
  to   { top: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { top: -10rem; }
  to   { top: 0; }
}
@keyframes fadeout {
  from { top: 0; }
  to   { top: -10rem; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { top: 0; }
  to   { top: -10rem; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { top: 0; }
  to   { top: -10rem; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { top: 0; }
  to   { top: -10rem; }
}

@-ms-keyframes fadeout2 {
  from { top: -10rem; }
  to   { top: 1rem; }
}

@keyframes showContainerGrs {
  from {right:-100%;}
  to {right: 1%;}
}



.fancybox-content{
  background: #fff!important;
}
.ipbContent{
  transition: all 1s ease-out;
  .frame:not(.frame-type-header,.frame-type-div,.fceSliderAdvanced .frame, .frame-type-b13-slideAdvanced-container  ){
    margin-bottom: 2rem;
  }
  .frame-type-header{
    margin-bottom: 0;
  }

  .frame-type-b13-slideAdvanced-container{
    .frame-default{
      margin-bottom: 0!important;
    }
  }

  header{
    h2{
      font-family: Georgia, serif;
      font-size: 22px;
      font-weight: normal;
      p{
        margin-bottom: 0!important;
        line-height: 1.2;
      }
    }
  }
}
.downloadItem{
  header{
    h2{
      font-size: 13px;
      font-weight: bold;
      font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
      padding: 0;
      margin: 0 0 6px 0;
    }
  }
}

.show-content{

}



body.career{
  .jobs-wrap{
    ul{
      li{
        list-style: none;
      }
    }
    .read-more-btn{
      &:before{
        display: none;
      }
      background: none;
      margin-top: 0;
    }
  }
}

body.alumni{
  .imgColumn_1{
    .image-embed-item{
      width: 220px;
      height: 220px;
      object-fit: cover;
      //object-position: top;
    }
  }
}
.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: 0;
  overflow: inherit;
}

.greyBack {
  .ce-bodytext{
    //max-width: 60%;
  }
  .image{
    margin-right: 1rem;
    .image-embed-item{
    }
  }
}
.greyBack2 {
  .ce-bodytext{
    max-width: 70%;
  }
  .image{
    margin-right: 1rem;
    .image-embed-item{
    }
  }
}

.group-drop{
  .group-drop-wrap{
    .person{
      margin-bottom: 1rem;
      .ipb__item{
      }
    }
  }
}
.button_ics{
  cursor: pointer;
}
// gallery

.ce-column{
  position: relative;
  @media only screen and (max-width: $md){
    margin-bottom: .25rem;
  }
}

.imgColumn_3:not(.frame-img3ColNotGallery .imgColumn_3),.imgColumn_4:not(.frame-img4ColNotGallery .imgColumn_4){
    .image{
      a{
        &:hover:before{
          content:'';
          position: absolute;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: .25;
          z-index: 0;
        }
        &:hover:after{
          font: normal normal normal 24px/1 "Material Design Icons";
          content: "\f43d";
          position: absolute;
          color:#f5f5f5;
          z-index: 0;
          top:50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2.5rem;
          opacity: .45;
        }
      }
    }
    .image-embed-item{
      @media only screen and (min-width: $md){
       width: auto;
        object-fit: cover;
        object-position: center;
      }
    }
}

.imgColumn_3:not(.frame-img3ColNotGallery .imgColumn_3){
  .image-embed-item{
    @media only screen and (min-width: $md){
      height: 260px;
    }
  }
}

.imgColumn_4{
  .image-caption{
    min-height: 40px;
  }
  .image-embed-item{
    @media only screen and (min-width: $md){
      //height: 220px;
    }
  }
}

.Kunstausstellungen{
  .imgColumn_4{
    .image-embed-item{
      @media only screen and (min-width: $md){
        height: 180px!important;
        width: 200px!important;
      }
    }
  }
}
ol{
  li{
    margin-bottom: 4px;
  }
  margin-bottom: 1rem;
}

.ce-column:last-child{
 // margin-bottom: 1rem;
}
.frame-type-multicolumn {
  .ce-column:last-child{
    margin-bottom: 0!important;
  }
  header{
    h2{
      //margin-bottom: 0!important;
    }
  }
}

.moreLinksContainer{
  a{
    &:after{
      display: none;
    }
  }
}

.frame-type-text a:visited {
  color: #0C66AC;
}

.frame-type-gridelements_pi1{
  .greyBack{
    header{
      h3{
        margin-bottom: 8px!important;
      }
    }
  }
}

@media only screen and (max-width: $md){
  .mfp-close-btn-in .mfp-close {
    right: 0!important;
  }
  .fancyLeft {
    position: relative;
    bottom: .5rem;
  }
  .entry .entry{
    padding: 1rem;
  }
  .menuBottom {
    margin-left: 8px;
  }
  #tabbar-drop-down .active-tab a {
    background: $green!important;
  }
  .newsHomepage .article{
    padding: .75rem !important;
  }
  .frame-buttonRightBottomHomePage {
    padding-left: 0!important;
  }
  body.search main .container .ipbContent {
    margin-top: 0!important;
  }
  body.search .gridContainer{
    flex-direction: column;
    display:flex;
  }
  .breadcrumb {
    width: 95%;
  }
  .searchIpbResultRow img {
    display: none!important;
  }
}

.researchIpb{
    .entry-drop-bottom-wrap{
      &:focus{
        border:none!important;
      }
    }
    .read-more-drop-btn{
      &:focus{
        border:none!important;
      }
      &.expanded{
        &:before{
          display: none!important;
        }
        &:focus{
          border:none!important;
        }
      }
    }
  }

body.intranet{
  @media only screen and (max-width: 992px){
    main {
      position: relative;
      margin-top: 180px;
    }
  }
}

table td:not(.organigramm_table td, .event-calendar table td, .grid td,.ui-datepicker-calendar td) {
  padding: .75rem;
}

.ce-headline-medium_distance,.ce-headline-medium_distance_subheader{
  margin-bottom: 1.5rem!important;
}
.ce-headline-big_distance,.ce-headline-big_distance_subheader{
  margin-bottom: 2rem!important;
}

.ce-headline-small_distance, .ce-headline-small_distance_subheader{
  margin-bottom: 1rem!important;
}

/* Space */
.frame-space-before-extra-small { margin-top: 1em!important; }
.frame-space-before-small { margin-top: 2em!important; }
.frame-space-before-medium { margin-top: 3em!important; }
.frame-space-before-large { margin-top: 4em!important; }
.frame-space-before-extra-large { margin-top: 5em!important; }
.frame-space-after-extra-small { margin-bottom: .3rem!important; }
.frame-space-after-small { margin-bottom: 2em!important; }
.frame-space-after-medium { margin-bottom: 3em!important; }
.frame-space-after-large { margin-bottom: 4em!important; }
.frame-space-after-extra-large { margin-bottom: 5em!important; }

body.intranet{
  iframe{
    width: 100%;
    height: 700px;
  }
}

body.ipbNewsletter{
  #c12015, #c12031 {
    .frame{
      min-height: 450px;
     // border:red solid;
    }
  }
  .gridColumns3{
    img{
      height:320px;
      width:100%;
      object-fit: cover;
    }
  }
}
// RTE
.tableEquipment{
  td{
    padding: .25rem;
    word-break: break-all;
    border-bottom: solid 1px #fff;
    border-right: solid 1px #fff;
    @media only screen and (min-width: $md){
      min-width: 150px;
      word-break: break-word;
    }
    &:first-child{
      border-left: solid 1px #fff;
    }
  }
  tr{
    &:first-child{
      background: #fff;
    }
  }

}

body.datenbanken-und-tools{
  .ipbContent, .frame-layout-5 h3 {
    margin-bottom: .75rem!important;
  }
  .ipbContent{
    .greyBack {
      min-height: 120px;
    }
    .greyBack2 {
      min-height: 170px;
    }
  }


}

.greyBack{
  .frame{
    margin-bottom: 0!important;
  }
}

.frame-type-gridelements_pi1 {
  header{

  }
  .frame{
    margin-bottom: 0!important;
    .imgColumn_1{
      min-height: 70px;
    }

  }
}

.news-text-wrap footer{
  background: none;
  min-height: auto;
}

#calendar table td{
  padding: 0!important;
}

.article .teaser-text div p{
    margin-bottom: 1px!important;
  }

.frame hr {
  margin: 1rem 0;
  color: #CBCBCB;
  border: none;
  background:#CBCBCB;
  height: 1px;

}
body.contact{
  .frame-type-b13-2cols-with-header-container{
    .greyBack{
      ul{
        margin-left: 0;
        li{
          list-style: none;
        }
      }
    }
  }
}

h2.slideAdvanced-container-hd {
  p{
    line-height: 1.5!important;
  }
}

body.fungizide {
  .ce-gallery figcaption{
    min-height: 50px;
    display: flex;
    align-items: flex-end;
  }
  .imgColumn_1{
    margin-bottom: 0!important;
    img{
      height: 208px;
      width:224px ;
      object-fit:cover;
    }
  }
  .gridColumns3{
    .frame{
      margin-bottom: 1.5rem!important;
    }
  }
}

 .frame-type-image{
   &.frame-layout-3{
     .ce-image{
       padding-bottom: 2rem;
     }
   }
 }

 .headerTableGrid:not(:first-child){
   margin-top: 2rem;
 }
.headerTableGrid{
  color: $black;
}


 // organigram
#c11968{
  @media only screen and (max-width: $lg){
    overflow-x: auto;
  }
}

body.intranet{
  .ipbMenu{
    display: none!important;
    opacity: 0;
  }
}

.events_showall{
  .more{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    align-content: center;
    justify-content: flex-end;
    .boxRight{
      float: none!important;
    }
  }
}

body.search {
  #custom-search ul li {
    border-top: none!important;
  }
}

.ipbContent{
  .employeeDetail{
    .fancyRight {
      float: left;
      width: 500px;
    }
  }
}

.person-email{
  a{
    word-break: initial;
  }
}
.entry{
  section.entry{
    img{
      height: 100%;
      object-fit: cover;
    }

  }
}

.newsItem-title-h3 p{
  line-height: 1.6;
  margin-bottom:0!important ;
}

.news-single{
  .header{
    h1{
      p{
        line-height: 1.25!important;
      }
    }
  }
}

.carousel{
  .hiddenForMobile{
    @media only screen and (max-width: $lg){
      display:none;
    }
  }
}
// rte

.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}




 // @media only screen and (min-width: $lg)



@import "var";

.logo{
  position: relative;
  width:275px;
  height: auto;
  display: block;
  @media only screen and (max-width: $lg){
    display: none;
  }
}



 .logo_sm{
   display: block;
   width:50px;
   height: auto;
   position: relative;
   z-index: 10;
   left:1rem;
   @media only screen and (min-width: $lg){
     display: none;
   }

 }

header.main-wrapper{
  padding-bottom: .75rem;
  padding-top: .75rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all .75s ease-out;
  @media only screen and (max-width: $lg){
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    height: auto;
    z-index: 100;
    background: #e2e2e2;
    width: 100%;
    top:0;
  }
}

.innerHeader{
  @media only screen and (max-width: $lg){

  }
}

.blockSearchLangMenu{
  @media only screen and (max-width: $lg){


  }

}

main{
  @media only screen and (max-width: $lg){
    position: relative;
    margin-top: 80px;
  }
}

header form{

  input{
    &:first-child{
      border: none;

    }
    &:focus-visible{
      border: none;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
}

.ButtonUserLogout input{
  background: transparent;
}

.main-wrapper .innerHeader{
  display: grid;
  grid-template-columns: 100px auto;
  position: relative;
  @media only screen and (min-width: $lg) {
    display: grid;
    grid-template-columns: 2fr auto;
  }

}
.blockSearchLangMenu{
  @media only screen and (min-width: $lg) {
    display: grid;
    grid-auto-rows: 50% 50%;
    justify-items: end;
  }
}
.ipbMenu{
  @media only screen and (min-width: $lg) {
  }


}

.language-menu {
  list-style: none;
  margin-bottom: .75rem;
  font-size: 0;
  position: relative;
  top:2px;
  right: 0;
  @media only screen and (min-width: $lg) {
    top:10px;
    right: 1px;
  }


  li{
    display: block;
    float:left;
    margin-right: 15px;
    &:last-child{
      margin-right: 0;
    }
    @media only screen and (min-width: $lg){
      margin-right: 5px;
    }

  }
  >.active{
    opacity: 0.2;

  }
}

.searchLangGrid{
  width: 90px;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr;
  z-index: 10;
  justify-items: start;
  justify-content: revert;
  align-items: start;
  top: 1rem;
  //float: right;
  right: 0;
  left: 130px;
  @media only screen and (min-width: $lg){
    width:auto;
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
    display: grid;
    grid-template-columns: 46px auto;
  }
}

.dropdown-search-mobile{
  width: 25px;
  height: auto;
  display: flex;
  justify-content: center;
  z-index: auto;
  @media only screen and (min-width: $lg){
    display: none;
  }
  .dropbtnSearch{
    border: none;
    background: none;
    cursor: pointer;
    margin-right: .5rem;
  }

  .dropdown-content-search{
    position: fixed;
    right: 0;
    left: 0;
    top: 5rem;
    width: 100vw;
    background: #f7f7f7;
    z-index: 10;
    display: none;
    padding-bottom: 1.5rem;
    padding-top: .5rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
  }

  .closeBtn{
    display: -webkit-inline-flex;
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    right: 1rem;
    top: -10px;
  }
  .tx-solr-search-form form input:first-child {
   // width: 80%;
  }

}
//sidemenu
 #subNavigation > li > ul > li.activeleve1 > a > p{color:#000; font-weight: 600}
 #subNavigation > li > ul > li > a:hover > p{color:#000; font-weight: 600; transition: ease-out all .2s}

 // login in header
.ipbLogin{display:none;
  position: absolute;
  top: 4px;
  right: 150px;
  @media only screen and (min-width: $md){
    right:370px;
  }
}


 fieldset.formloginIpbHeader{
    border: none;
   -webkit-transition: all .25s ease;
   -moz-transition: all .25s ease;
   -ms-transition: all .25s ease;
   -o-transition: all .25s ease;
   transition: all .25s ease;
}


 .dropbtn {
   cursor: pointer;
   &:before{
     font-family: 'Linearicons';
     content: "\ea1e";
     font-size: 16px;
     margin: 0 7px ;

   }
 }

 @media only screen and (max-width: $md){
   .dropbtn {
     cursor: pointer;
     font-size: 0;
     &:before{
       font-family: 'Linearicons';
       content: "\ea1e";
       font-size: 18px;
       margin: 0 7px ;
       position: relative;
       top:12px;


     }
   }
 }

 .dropbtn:hover, .dropbtn:focus {
   background-color: transparent;
 }

 .dropdown {
   position: relative;
   display: inline-block;
 }

 .dropdown-content {
   display: none;
   position: absolute;
  // left:-150px;
   background-color: #f1f1f1;
   min-width: 160px;
   overflow: auto;
   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   z-index: 1;
   h3{
     text-align: center;
   }
   .wrForm{
     padding: 1rem;
     background: #fafafa;
     width: 100vw;
     left: 0;
     right: 0;
     position: fixed;
     @media (min-width:$lg){
       width: inherit;
       left: auto;
       right: auto;
       position: relative;
     }
   }
   form {
     background: transparent;
     input{
       margin-top: .75rem;
       padding: 10px ;
       width: 100%;
       @media (min-width:$lg){
         width: auto;
       }
     }
   }
 }


 .show {display: block;}

.no-border{
  border: none;
}
.textCenter{
  text-align: center;
  display: block;
}
 .textRight{
   text-align: right;
   display: block;
 }
.userLogout {
  cursor: pointer;
  &:before{
    font-family: 'Linearicons';
    content: "\ea1e";
    font-size: 16px;
    margin: 0 7px ;

  }
}
.ButtonUserLogout input {
  cursor: pointer;
  font-size: 12px;
  text-decoration:underline ;
}
 @media (max-width:$md){
   .dropdown-content {
     z-index: 100;
     top: 3rem;
   }
 }


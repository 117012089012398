.ipb_Login{
  display: none;
}

body{
  .innerHeader{
    .ipb_Login{
      position: relative;
      top:-1.5rem;
      @media only screen and (min-width: $lg){
        position: relative;
        right: 0;
        @-moz-document url-prefix() {
          right: 0!important;
        }
      }

      .ausLogg{
        position: relative;
        right: 0;
        .ausLoggBtn{
          margin-left: 1rem;
        }
      }

      form{
        border:none;
        fieldset{
          border:none;
          @media only screen and (min-width: $lg){
            display: flex;
            justify-content:flex-end;
            align-items:center;
          }
          legend{
            font-weight: bold;
          }
          div{
            input{
              &[type="submit"] {
                background: #F2F2F2;
                border: 1px #CBCBCB solid;
                border-radius: 3px;
                color: #484F56;
                display: flex;
                justify-content:flex-end;
                align-items:center;
                top:10px;
                position: relative;
                padding: .35rem .75rem;
                cursor: pointer;
                &:hover{
                  background: #899e00;
                  color: #EEEEEE;
                  border: 1px #CBCBCB solid;
                  border-radius: 3px;
                }
              }
            }
            label{
              display: block;
              input{
                display: block;
                box-sizing: border-box;
                padding: 5px;
                border:  1px #CBCBCB solid;
                height: 28px;
                margin-top: 3px;
                margin-right: 1rem;
                &:focus-visible{
                  border:  1px #888888 solid;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: $lg){
  .innerHeader{
    .ipb_Login_mobile{
      position: relative;
    }

    .profile {
      position: absolute;
      display: flex;
      cursor: pointer;
      top:12px;
      left:100px;


      &:before{
        font-family: 'Linearicons';
        content: "\ea1e";
        font-size: 18px;
      }
    }

    .form_login {
      position: absolute;
      top: calc(100% + 44px);
      right: 13%;
      width: 100%;
      height: auto;
      background: #fff;
      box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
      opacity: 0;
      transform: translateY(-10px);
      visibility: hidden;
      transition: 300ms;
      padding: 1rem;
        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: calc(35vw - 2px );
          width: 20px;
          height: 20px;
          background: #fff;
          transform: rotate(45deg);
          z-index: -1;
        }
    }

    .form_login.active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
@media only screen and (max-width: $lg){
  .profile{
    display: none;
  }
}



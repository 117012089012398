.facets{
  width: 100%;
  margin-bottom: 2px;
  position: relative;
  color: white;
  line-height: 1.5;
  font-weight: normal;
  a{
    color:white;
    &:hover{
      color:#899E00;
    }
  }
}

#custom-search ul li ul li a{
  background: none!important;
  padding-left: 0!important;
}
#custom-search h3 {
  padding-top: 8px;
}

.FacetFilter{
  //display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media only screen and (min-width: $lg){
    column-count: 3;
  }
  .list-group-item{
    @media only screen and (min-width: $lg){
      width: auto;
    }
    &:before{
      font: normal normal normal 13px/1 "Material Design Icons";
      content: "\f14f";
      padding-left:.75rem;

    }
  }
}

.query-debug{
  display: none!important;
}
.tx-solr-search-form{
  form{
    input{
      &:first-child{
        height: 40px;
        box-sizing: border-box;
        padding: .5rem;
        width: 81%;
        @media only screen and (min-width: $lg){
          width: 93%;
        }

      }
    }
    button{
      width: 40px;
      height: 40px;
      padding: 18px;
      box-sizing: border-box;
      font-size: 0;
      position: relative;
      top:-5px;
      cursor: pointer;
      &:before{
        content: "\f43b";
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: 22px;
        text-align: center;
        position: absolute;
        top:.5rem;
        left:.5rem;
        color:#202941;
      }
      &:hover:before{
        opacity: .5;
      }
    }
  }
}

.sortingSearchMenu{
  border-top:none;
  li{
    border: none;
    padding-right: 1rem;
    display: inline;
  }

}

.glyphicon-arrow-up,.glyphicon-arrow-down{
  &:after{
    font: normal normal normal 24px/1 "Material Design Icons";
    position: relative;
    justify-content:center;
    align-items: center;
    text-align: center;
    top:4px;

  }
}
.glyphicon-arrow-up{
  &:after{
    content: "\f452";
  }
}

.glyphicon-arrow-down{
  &:after{
    content: "\f44f";
  }
}
.listSorting{
  border: none;
  li{
    border-top: none!important;
    display: inline;
    padding-right: 10px;
  }
  .active{
    font-weight: 800;
  }
}

.secondaryContentSection{
  display: flex;
  button.dropdown-toggle{
    margin-right: 20px;
    background: none;
    //color:white;
    font-weight: 600;
    border:none;
  }
}

.searchinfo{
  line-height: 2;
  border-bottom: solid #666 1px;
  padding-bottom: .75rem;
}

.solr-pagination{
  margin-top: .5rem;
  margin-bottom: .75rem;
  ul.pagination{
    li{
      list-style: none;
      display: inline;
    }
  }
}
.searchIpbResultRow{
  &:first-child{
    margin-top: 1rem;
  }
  background: #D8D8D8;
  margin-bottom: 2rem;
  padding: 1rem;
  line-height: 1.5;
}

.tx-solr-q:focus-visible,.tx-solr-q:focus  {
  border: solid 1px #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


/* -------------------- Suche ----------------- */

.tx_solr{

}
#searchform {

}

.moreLinksContainer {
  overflow: hidden;
  margin:  10px 0 0;
}

#searchfield {
  /* background:url(../../img/searchfield.png) no-repeat;*/
  display: block;
  width: 250px;
  height: 2rem;
  float: left;
  border: none;
  font-family: Verdana;
  font-size: 13px;
  color: #29323A;
  padding-left:.8rem;
  box-sizing: border-box;
  outline: none!important;
}

#searchbtn {
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVR4nO2Zu24TQRSGPxeElgYbEVIYHoECCJdXQAIH+YKECA+AxEVESAl0wAsgUUEKpCiiIDFKDYGGS96AhhZSBZGQ2MFwpH+lKQxi8czYu+wnjbTy5f/3jPecOTOGgoKR5AAwDcwDa8A6sKPxBfig9y4DFUaQM0Ab6AC9vxz22WXgNCPAEWDFubku8Aq4BhwDysAeDbs+DlwHVvXZ5HsvgMPDCqIFfNWNfAPuAftTfN8Cuw9sSmMDaBCZOWc2F4DxAbQOAYuO3iyRuCvDH7ouedK9CuzGCqYlIzOsBdCfcoKpEzCxk5y4FcoEmHFyphrCYMXJiZCUnJxph1gneqowE4RnXJWwB5zyKdyWqJXYWFhpNs8ln21HRwtYmnViUGyd6aq9seuBuaKZeUl8Xsv7kg+xeYlZ2xGbG/J+7ENsTWLWO8VmUt7vfIitSyxmfiRU5P3Zh9i2xMaIz155f89LIBt5ebQ++kx22xTF5qS8rQznovw+8iE2LTHbnsbmjbzP+XpOkxbFS6uQskXpAPt8iS5rZqyRi8UDeVpL740TEt0aQht/1Ld425khX/v0fpj2M3k9D2FQ1cLU03Y0FLflYSeTB0OZNJzDBzso8M0F5/DhLIGZdYKZ8fSYlfRLJEHs6sSGWMEkOWOHbP/KhJMT7rDS2yQCdSdnNlWa06wzZZXYLacx7PYJpkEEqk41S4xX1VpMajEd06jotZvqndybXlBiN4cZDDqyWUr5t4JtD572aUZ/F0ydiJR1UPAEeK8Suq3F7RPwFniom/rTtqAxCsH4wsp7p08wF8lRMC0yyFSegqn9D8E0yWgwO0UwGSkAc2SU885jdoeMU4v5d3ZBwa8Z+Ak4rhMJLuUtjQAAAABJRU5ErkJggg==) #FFF no-repeat;
  background-size: 70%;
  outline: -webkit-focus-ring-color auto 0px;
  background-position: center center;
  display:block;
  width: 37px;
  height: 2rem;
  float:left;
  border:none;
  outline: none!important;
  &:focus-visible, &:focus{
    outline: none!important;
  }
  :-moz-focusring {
    outline: none!important;
  }
}

.headerSearch{
  position: relative;
  >button.tx-solr-submit{

  }

  .tx-solr-search-form {
    form {
      background: white;
      height: 30px;
      width: 100%;
      max-width: 250px;
      input{
        &:first-child{
          height: 30px;
        }
      }
      button{
        border-radius: 0;
        border: none;
        background: transparent;
        position: absolute;
        top:-8px;
        height: 30px;
        &:before{
          content: '';
          background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVR4nO2Zu24TQRSGPxeElgYbEVIYHoECCJdXQAIH+YKECA+AxEVESAl0wAsgUUEKpCiiIDFKDYGGS96AhhZSBZGQ2MFwpH+lKQxi8czYu+wnjbTy5f/3jPecOTOGgoKR5AAwDcwDa8A6sKPxBfig9y4DFUaQM0Ab6AC9vxz22WXgNCPAEWDFubku8Aq4BhwDysAeDbs+DlwHVvXZ5HsvgMPDCqIFfNWNfAPuAftTfN8Cuw9sSmMDaBCZOWc2F4DxAbQOAYuO3iyRuCvDH7ouedK9CuzGCqYlIzOsBdCfcoKpEzCxk5y4FcoEmHFyphrCYMXJiZCUnJxph1gneqowE4RnXJWwB5zyKdyWqJXYWFhpNs8ln21HRwtYmnViUGyd6aq9seuBuaKZeUl8Xsv7kg+xeYlZ2xGbG/J+7ENsTWLWO8VmUt7vfIitSyxmfiRU5P3Zh9i2xMaIz155f89LIBt5ebQ++kx22xTF5qS8rQznovw+8iE2LTHbnsbmjbzP+XpOkxbFS6uQskXpAPt8iS5rZqyRi8UDeVpL740TEt0aQht/1Ld425khX/v0fpj2M3k9D2FQ1cLU03Y0FLflYSeTB0OZNJzDBzso8M0F5/DhLIGZdYKZ8fSYlfRLJEHs6sSGWMEkOWOHbP/KhJMT7rDS2yQCdSdnNlWa06wzZZXYLacx7PYJpkEEqk41S4xX1VpMajEd06jotZvqndybXlBiN4cZDDqyWUr5t4JtD572aUZ/F0ydiJR1UPAEeK8Suq3F7RPwFniom/rTtqAxCsH4wsp7p08wF8lRMC0yyFSegqn9D8E0yWgwO0UwGSkAc2SU885jdoeMU4v5d3ZBwa8Z+Ak4rhMJLuUtjQAAAABJRU5ErkJggg==) #FFF no-repeat;
          background-size: 70%;
          outline: -webkit-focus-ring-color auto 0px;
          background-position: center center;
          display:block;
          width: 40px;
          height: 30px;
          float:left;
          border:none;
        }
      }
    }
  }

}
.result-header{
  .col-md-10{
    //display: none;
  }
}


.result-item{
  position: relative;

  .categorie {
    /*display: block;
    font-size: 10px;
    color: #45525F;
    position: absolute;
    top: 5px;
    right: 16px;*/
    text-align: right;
  }
}

.results-topic,.results-teaser{
  display: none;
}


 body.search main div.container div.gridContainer div.ipbContent div#c1190.frame.frame-default.frame-type-list.frame-layout-0 div.tx_solr.container div.row div.col-md-9 div#tx-solr-search div#custom-search{

  padding: 20px 20px 0 20px;
  margin-bottom: 2px;
  position: relative;
}

body.search{
  .results-topic,.results-teaser{
    display: block;
  }

  main{
    .container{

        .ipbContent{
          margin-top: 2rem;
          .tx_solr{
            .col-md-3{
              display: none;
            }
            #tx-solr-sorting{
              display: none;
            }
          }
          .colorRed {
            color:red;
          }
        }
        aside{
          .aside-nav{
            display: none;
          }
          .aside-nav-wrap{
            margin: 0!important;
          }
          .entry{
            .tx_solr{
              margin-top: 2rem;
              h3{
                display: none;
              }


              .col-md-9,.plusMinus{
                display: none;
                height: 0;
                width: 0;
                visibility: hidden;
                opacity: 0;
                .listCol1{
                  display: block;
                  font-size: 20px;
                }
              }
              .FacetFilter  {
                display: block;
              }
              .klappOut{
                display: block!important;
              }
              .listCol1{
                display: block!important;
              }
            }
          }
        }
      }
    }
  }

body.search main div.container div.gridContainer div aside div.entry div#c1236.frame.frame-default.frame-type-list.frame-layout-0 div.tx_solr.container div.row div.col-md-3 div#tx-solr-search-functions div#custom-search div.item.klappOut div#tx-solr-faceting div.facet-area-main div.solr-facets-available.secondaryContentSection div.facets div#facet-accordion-type.facet.facet-type.facet-type-options.panel-group ul li.faccetToggle{border-top: none!important;}

// drobdown search mobile

@media only screen and (max-width: $lg){
  .headerSearch{display: none}

}

.photoEmplSolr{
  float: left;
  width: 100px;
  margin-right: 1.5rem;
}

 body.home  .tx-solr-search-form,body.home #results-per-page,body.home #tx-solr-sorting{
  display: none;
}

body.home .result-found, body.home .result-range{
  display: none;
}

.moreLinksContainer {
  position: relative;
  top: .5rem;
}

.pagination, .f3-widget-paginator{
  position: relative;
  .mdi{
    font-size: 1rem;
  }
}
.first{

}
.f3-widget-paginator{
  margin-top: 2rem;
  margin-left: 0!important;
}

ul.pagination > li.page-item.active,.news-list-view > ul > li.current {
  background:#e2e2e2;;
  color:  #0C66AD;
  padding: 0 4px;
  border-radius: 3px;
}


@media only screen and (max-width: $lg){
  #categorie_selector {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding-left: 0!important;
  }
}

#projectsSub {
  width: 100%!important;
  max-width: 1000px!important;
}

body.home{
  .tx_solr{
    .col-md-3,.solr-pagination{
      display:none!important;
    }
  }
}

.moreLinksContainer .expanded{
  &:before{
    content: "\f452" !important;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 18px;
    text-align: center;
    display: none!important;
  }
}

.tx_solr .plusMinus{
  background: none!important;
  &:after{
    content: "\f506" !important;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 24px;
    color:#ffffff;
  }
  &:focus{
    border:none!important;
  }
}

.tx_solr .expanded{
  background:none;
  &:before{
    display: none!important;
  }
  &:after{
    content: "\f467" !important;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 24px;
    color:#ffffff;
  }
}
.faccetButton{
  .expanded{
    &:after{
      display: none!important;
    }
  }
}


.home{
  #custom-search-download{
    display: none!important;
  }
}

.search {
    @media only screen and (min-width: $lg){
      .FacetFilter {
        column-count: 1;
      }
    }
  }

.tx-solr-search,.hidden-xs{
  position: relative;
}

#custom-search-download{
  height: 28px;
  border-bottom: 1px solid;
  margin-bottom: 8px;

}
.result-range{
  margin-bottom: 8px;
  display: inline-block;
}
.forschung{
  .tx-solr-search-form{
    position: relative;
    form{
      padding-bottom: 60px;
      margin-bottom: 8px;
    }
  }
  .perPage{
    display: flex;
  }
}
  #tx-solr-sorting {
    display: flex;
    align-items: baseline;
    position: absolute;
    top: -2.5rem;
    margin-left: 1.5rem;
    .glyphicon-sort{
      color:#FFF;
    }
    .listSorting{
      li{
        a{
          color: #ffffff;
        }
      }
    }
  }

.FacetsActive{
  background: $black;
  color: #FFFFFF;
  padding: 1rem;
  a{
    color: #a4a6b1!important;
    display: block;
  }
  .box-icon-icon{
    font-size: 16px;
    display: inline-flex;
    justify-items: center;
    color:#FFFFFF;
    &.mdi-close-circle-outline{
      font-size: 22px;
      position: relative;
      top:4px;
    }
  }
}

.document-score-analysis, .hidden-xs{
  display: none;
}
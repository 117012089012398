.tx-dftabs-tabMenu {
  margin-bottom: 15px; }

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabTitle, .tx-dftabs-plugin1-hasJS .tx-dftabs-tabContent {
  display: none; }

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabMenu, .tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
  display: block; }

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
  overflow: hidden; }

.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a, .nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:focus, .nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:hover {
  color: #555;
  cursor: default;
  background-color: #FFF;
  border: 1px solid #ddd;
  border-bottom-color: transparent; }

.m-tabs__tablist {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  background-color: transparent;
  justify-content:end;
  @media only screen and (min-width: $lg){

  }

}
.m-tabs .responsive-tab-more, .m-tabs__tab {
  font-family: Georgia, serif;
  margin-right: .25rem;
  &:last-child{
    margin-right: 0;
  }
  font-size: 11px;
  @media only screen and (min-width: $md){
    font-size: 20px;
    margin-right: 1rem;
  }
  color: #1d1f34;
  padding: 10px 15px;
  border: 0;
  background-color: transparent;
  white-space: nowrap;
  position: relative;
  transition: background-color 0.2; }

#tab-11434{
}

/*.m-tabs .responsive-tab-more::after, .m-tabs__tab::after {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #899e00;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out; }*/

.m-tabs .responsive-tab-more:hover, .m-tabs__tab:hover {
  background-color: #899e00;
  color: #fff;
  border-radius: 10px;
}
.m-tabs .responsive-tab-more[aria-selected='true'], .m-tabs__tab[aria-selected='true'] {
  //background-color: transparent;
  //color: #1D1F32;
  background: #899e00;
  color: #fff;
  border-radius: 10px;
}
.m-tabs .responsive-tab-more:hover, .m-tabs .responsive-tab-more:focus, .m-tabs__tab:hover, .m-tabs__tab:focus {
  outline: 0; }
.m-tabs .responsive-tab-more:hover::after, .m-tabs .responsive-tab-more:focus::after, .m-tabs__tab:hover::after, .m-tabs__tab:focus::after {
  transform: none;
  opacity: 1; }
.m-tabs .responsive-tab-more {
  padding-right: 35px;
  position: relative; }
.m-tabs .responsive-tab-more::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) translateY(-1px); }
.m-tabs .responsive-tabs-off-screen {
  text-align: left;
  box-shadow: 0 1px 3px rgba(85, 85, 85, 0.12), 0 1px 2px rgba(85, 85, 85, 0.24); }
.m-tabs__panel--closed {
  display: none; }

@media only screen and (max-width: $md){
  .m-tabs__tablist {
    justify-content: center;
  }
  .m-tabs__tab {
    padding: 10px;
  }

  #tab-11434{

  }
  .m-tabs__tablist{
    button:first-child,button:nth-child(2),button:nth-child(3),button:nth-child(4){
      font-size: 0px;
      margin-right:1.5rem;
      &::before {
        //font-family: "Linearicons";
        //content: "\e9d5";
        display: inline-block;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: 2rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
        content: "\f485";
        margin: 0 7px;
      }
    }
    button:nth-child(2){
      &::before {
        content: "\f1e7";
      }
    }
    button:nth-child(3){
      &::before {
        content: "\f10f";
      }
    }
    button:nth-child(4){
      &::before {
        content: "\f1b3";
      }
    }

  }





}

.submenu2{
  li{
    border-top: 1px #cbcbcb solid;
    padding: 5px 0 5px;
    a{}
  }
}

.borderHidden{
  border:none!important;
}

.submenu2{
  .activeSubMenu1{
    color: #000!important;
    font-weight: 600!important;;
    transition: ease-out all 0.2s!important;
  }
}

.aside-nav{
  border-bottom: 1px #cbcbcb solid;
}
.submenu3,.submenu4{
  margin-left: 1rem;

  .activeSubMenu3,.active{
    color: #a1ae1b!important;
  }
}

.submenu2{
    &:hover{
      color: #a1ae1b;
    }
    &:active{
      color: #a1ae1b;
    }
  }


.submenu-opener > .fa{
  font-size: 14px;
  float: right;
  position: relative;
  top:-18px;
  opacity: .7;
}

.separator  a{color:red!important;font-size: 0!important;}

.aside-nav-wrap .aside-nav ul {
  position: relative;
}

.submenu3, .submenu4{
  display: none;
}
.activeSubMenu1 ~ .submenu3,.sub4MenuList{
  display: block;
}


.activeSubMenu3  ~ .submenu4{
  display: block;
}

.sub4MenuList,.submenu4,.submenu3{
  //display:block;
  li{
    padding: 0!important;
  }
}


.sidebar{
  .frame-buttonRightBottomHomePage {
    margin-bottom: 0.25rem!important;
  }

}

body.contact{
  main{
    .frame-layout-5{
      padding: 10px;
      p{
        margin-bottom: 0;
      }
    }
    .ce-intext.ce-left .ce-gallery {
      margin-right: 7px;
    }
    .gridContainer{
      .entry{
        h2{font-size: 20px}
        .ce-bodytext{
          width: 100%;
          margin: 0;
          padding: 0;
          font-size: 13px;
          p{
            margin-bottom: 0;
          }
        }
        .ce-gallery{
        }
        .frame-type-textpic{
          .ce-textpic{
            img{
              height: 120px;
              width: 90px;
              object-fit: cover;
              float: left;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
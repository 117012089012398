@media print {

  a{
    color:#000!important;
  }
  a:hover, a:active, a:visited{
    color:blue!important;
  }

  .logo_sm,.logo,.blockSearchLangMenu,.tx-dftabs-plugin1,.print,.lastModified,
  .breadcrumb,.group-drop-wrap,.f3-widget-paginator,.solr-pagination,.tx-solr-search-form,#results-per-page,.result-range,
  .result-found, .button_ics, .news .additional, #actions,div.letterHeader,.main-wrapper
  {display: none!important;visibility: hidden!important;}
}

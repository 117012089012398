/**************************************/
/* Default styles for news extension */
/**************************************/

.news {
  .clear {
    clear: both;
  }

  .nav {
    ul {
      float: left;
      display: inline;
      margin: 0;
    }
    li {
      float: left;
    }
    a {
      float: left;
      display: block;
    }
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }

  .article {
    padding-bottom: 0;
    margin-bottom: 0;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .news-img-wrap {
      float: left;
      margin: 0px 12px 5px 0;
      background: transparent;
      a {
        display: block;
        float: left;
      }
      @media only screen and (min-width: $md) {
        img:not(.imagenewsHomepage) {
          float: left;
          width: 100%;


        }
      }
    }
    .teaser-text {
      margin: 0 0 10px 0;
      word-break: break-word;

    }
    h3 {
      margin-bottom: 0;
    }
  }

  .footer {
    clear: both;
    padding: .5px 0 .5rem 0;
    color: #888;
    p {
      line-height: 1em;
      font-size: 12px;
      font-weight: 100;
      color: #c3c4c1;
    }
    span {
      display: inline-block;
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid #ddd;
      &:last-child {
        border-right: 0;
      }

    }
    .news-category a {
      text-transform: uppercase;
    }
  }
  .no-news-found {
    color: #DB0202;
    font-style: italic;
  }

  .page-navigation {
    p {
      float: left;
    }
    ul {
      float: right;
      li {
        float: left;
        display: block;
        padding: 0 2px;
        background: none;
        a {
          display: block;
        }
      }
    }
  }
}

/* category menu view */
.news-category-view {
  ul li {
    padding: 5px 8px;

    li {
      padding: 2px 8px 2px 12px;
    }
  }
  > ul > li:nth-child(even) {
    background: #f9f9f9;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .category-desc {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #ddd;
  }
}

/* single view */
.news-single {
  .footer {
    margin-bottom: 1rem;
    margin-top: 5px;
    padding: 10px 0 0 0;
    border-bottom: 0;
    border-top: 1px solid #eee;
  }
  .article .news-img-wrap {
    float: right;
    width: 282px;
    margin: 0 0 0 25px;
    img {
      float: none;
    }
  }
  .teaser-text {
    font-size: 15px;
    color: #888;
  }
}

.news-text-wrap {
  margin-bottom: 10px;
  p{
    padding-bottom: 1rem;
  }
  h3{
    padding: 0;
    font-family: Georgia, serif;
    font-size: 18px;
    margin-bottom: .5rem!important;

  }
}

.news-img-caption {
  color: #888;
}

/* related news + files */
.news-related-wrap {
  display: table;
  margin-top: 20px;
  width: 100%;
}

.news-related {
  width: 50%;
  padding: 15px;
  border: 1px solid #eee;
  display: table-cell;

  ul li {
    line-height: 1em;
    margin-bottom: 12px;

    span {
      border-right: 1px solid #DDDDDD;
      display: inline-block;
      margin-right: 8px;
      padding-right: 8px;
      color: #888;

      :last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
  h4 {
    margin-bottom: 10px;
  }
}

.news-related-files {
  ul li {
    margin-bottom: 20px;
  }
  .news-related-files-link .jpg {
    background-position: 0 0;
  }
}

.news-clear {
  clear: both;
}

// ipb style

.f3-widget-paginator {
  li{
    display: inline;
    list-style: none;
    padding-right:5px
  }
}
.newsHomepage .article {
  //-webkit-box-shadow: 0 0 16px 1px rgba(81,81,81,.1);
  //box-shadow: 0 0 16px 1px rgba(81,81,81,.1);
  word-wrap: break-word;
  background-color: #f2f3f4;
  background-clip: border-box;
  padding: 0!important;

}

.newsHomepage .news-img-wrap {
  float: none;
  margin: 0 0 0 0!important;
  line-height: 0;

}

.newsHomepage.news-img-wrap img {
    float: none;
    height: auto;
    width: auto;
  }
.newsHomepage .header h3{
  a{
    span{
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
    }
  }
}

.newsHomepage .news-img-wrap{
  background: transparent;
}

  .newsHomepage{
    margin-bottom: 2rem;
    .news .article .news-img-wrap img {
      float: none;
      height: auto;
      width: auto;

    }
    .news .article .news-img-wrap a {
      display: block;
      float: none;
    }

    @media only screen and (min-width: $lg) {
      display: grid;
      gap:2rem;
      .teaser-text{
        padding: 1rem;
      }
      }

    .footer{
      clear: none;
      margin-top: 0;
      border-top: none;
      padding: 0;
    }
  }

.newsHomepage .news-img-wrap {
  margin: 0px 12px 5px 0;
  background: transparent;
  a {
    display: block;
    float: left;
  }
  img {
    float: left;
    width: 100%;
    object-fit: cover;
    margin-right: 2rem;
    min-height: 225px;
  }
  @media only screen and (min-width: $lg){
    float: left;
    width: 320px;
  }
}

.newsHomepage .news-list-date{
    color: inherit;
    font-size: 16px;
    font-weight: 100;
}
.newsHomepage  .teaser-text{
  h3{
    font-size: 14px;
    font-weight: normal;
    padding: 0;
  }
  strong{font-weight: normal}
  @media only screen and (min-width: $lg) {
    padding: 1rem;
  }
}

.news-list-view{
  .article {
    margin-bottom: 0;
    background-color: inherit;
    padding: 0 ;
    border-bottom: solid .11rem #888888;

  }

  .news-img-wrap {
    float: left;
    margin: 0px 12px 5px 0;
    background: transparent;
    a {
      display: block;
      float: left;
    }
    @media only screen and (min-width: $md) {
      img:not(.imagenewsHomepage) {
        float: left;
        width: 250px;
        height: 150px;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}

.newsHomepage{
 .article {
    margin-bottom: 0;
    background-color: #f1f1f1;
    padding: 1rem;
   border-bottom: none;
  }
}

.news-list-view{
  .teaser-text{
    .description{
      h3{display: none!important;}
    }
  }

  h3{
    a{
      color:#0C66AD;
    }
  }
}

.news-list-view:not(.newsHomepage) {
  div.article.articletype-0 div.teaser-text div h3:not(.newsHomepage){
    //display: none!important;
    font-family: Verdana, Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 300;
    strong{
      font-weight: 300;
    }
  }
  h3{
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 18px;
  }

}

.newsHomepage{
  .news-list-date{
    font-size: 12px;
    margin-bottom: .25rem;
  }
}
.news-single{
  .article {
    .news-img-wrap{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: .75rem;
      @media only screen and (max-width: $lg){
        float: none;
        width: 100%;
        margin: 1rem auto;
      }
      .outer{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: .5rem;
        img{
        }
        p{
          font-size: 12px;
          padding: .5rem;
          display: inline-block;
        }
      }
    }
  }
}

.news-img-wrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.75rem;

  &.additional{
    float:none!important;
    margin: 0 0 0 0px!important;
    @media only screen and (min-width: $lg){
      flex-direction: row!important;
    }

    width: auto!important;
    justify-content: left!important;
    flex-wrap: wrap;

    .outer{
      box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
      align-items: self-start!important;
      display: flex;
      flex-direction: column;
      float: left;
      height: auto;
      min-height: 200px;
      width: 100%;
      @media only screen and (min-width: $lg){
        width: 200px;
        height: 200px;
      }
      @media only screen and (max-width: $lg){
        box-shadow:none!important;
      }

      &:first-child{
        display: none;
      }
      &.gridImgNews{
        min-height: 230px;
        height: auto;
      }
      img{
        @media only screen and (min-width: $lg){
          object-fit: cover;
          height: 200px!important;
          width: 200px!important;
        }
      }
    }
  }
}

.news{
  .more{
    text-align: right;
    display: block;
    font-size: 12px;
  }
}

@media only screen and (max-width: $lg){
  .newsHomepage{
    .article{
      padding: 1rem;
      margin-bottom: 2rem;
    }
  }
}
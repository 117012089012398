.gridColumns4,.gridColumns3,.gridColumns2{
  @media only screen and (min-width: $lg){
    display: grid;
    gap: 1.5rem;
  }
}

.gridColumns3{
  @media only screen and (min-width: $lg){
    grid-template-columns: repeat(3, 1fr);
  }
}

.gridColumns2{
  @media only screen and (min-width: $lg){
    grid-template-columns: repeat(2, 1fr);
  }
}

.gridColumns4{
  @media only screen and (min-width: $lg){
    grid-template-columns: repeat(4, 1fr);
  }
}
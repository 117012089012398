
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

 #ipbmenu,
 #ipbmenu ul,
 #ipbmenu ul li,
 #ipbmenu ul li a,
 #ipbmenu #menu-button {
   margin: 0;
   padding: 0;
   border: 0;
   list-style: none;
   line-height: 1;
   display: block;
   position: relative;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }

#ipbmenu{
  z-index: 1000;
  transition: all 1s ease-out;
}
.ipbmenu button{
  &:focus-visible{
    border: none!important;
    box-shadow: none!important;
    background: transparent!important;
  }
}

#ipbmenu:after,
#ipbmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#ipbmenu #menu-button {
  display: none;
}
#ipbmenu {
  font-family: Georgia, serif;
  font-size: 19px;
  background: transparent;
}
#ipbmenu > ul > li {
  float: left;
}
#ipbmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#ipbmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#ipbmenu.align-center ul ul {
  text-align: left;
}
#ipbmenu.align-right > ul > li {
  float: right;
}
#ipbmenu > ul > li > a {
  padding: 10px;
  text-decoration: none;
  color: #000;
}
#ipbmenu > ul > li:hover > a {
  color: #ffffff;
  background: $black;
}
#ipbmenu > ul > li.has-sub > a {
  padding-right: 34.5px;
}
#ipbmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 22px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}
#ipbmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 19px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
#ipbmenu > ul > li.has-sub:hover > a:before {
  top: 23px;
  height: 0;
}
#ipbmenu ul ul {
  position: absolute;
  left: -9999px;
}
#ipbmenu.align-right ul ul {
  text-align: right;
}
#ipbmenu ul ul li {
  height: 0;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
#ipbmenu li:hover > ul {
  left: auto;
}
#ipbmenu.align-right li:hover > ul {
  left: auto;
  right: 0;
}
#ipbmenu li:hover > ul > li {
  height: 40px;
  background:$black;
}
#ipbmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}
#ipbmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}
#ipbmenu ul ul li a {
  border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  padding: 11px 15px;
  min-width: 225px;
  font-size: 13px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #dddddd;
  font-weight: 400;
  background:$black;
}
#ipbmenu ul ul li:last-child > a,
#ipbmenu ul ul li.last-item > a {
  border-bottom: 0;
}
#ipbmenu ul ul li:hover > a,
#ipbmenu ul ul li a:hover {
  color: #ffffff;
}
#ipbmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}
#ipbmenu.align-right ul ul li.has-sub > a:after {
  right: auto;
  left: 11px;
}
#ipbmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 13px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
#ipbmenu.align-right ul ul li.has-sub > a:before {
  right: auto;
  left: 14px;
}
#ipbmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}
@media all and (max-width: 768px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px), only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min-resolution: 192dpi) and (max-width: 1024px), only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  #ipbmenu {
    width: 100%;
  }
  #ipbmenu ul {
    width: 100%;
    display: none;
  }
  #ipbmenu.align-center > ul {
    text-align: left;
  }
  #ipbmenu ul li {
    width: 100%;
    border-top: 1px solid rgba(120, 120, 120, 0.2);
    &:last-child{
      border-bottom: 1px solid rgba(120, 120, 120, 0.2);
    }
  }
  #ipbmenu ul ul li,
  #ipbmenu li:hover > ul > li {
    height: auto;
  }
  #ipbmenu ul li a,
  #ipbmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
  }
  #ipbmenu > ul > li {
    float: none;
  }
  #ipbmenu ul ul li a {
    padding-left: 25px;
  }
  #ipbmenu ul ul ul li a {
    padding-left: 35px;
  }
  #ipbmenu ul ul li a {
    color: #000;
    background: #f7f7f7;
    text-shadow: none;
    font-family: Verdana, Arial, Helvetica, sans-serif;
  }
  #ipbmenu ul ul li:hover > a,
  #ipbmenu ul ul li.active > a {
    color: #888888;
  }
  #ipbmenu ul ul,
  #ipbmenu ul ul ul,
  #ipbmenu.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }
  #ipbmenu > ul > li.has-sub > a:after,
  #ipbmenu > ul > li.has-sub > a:before,
  #ipbmenu ul ul > li.has-sub > a:after,
  #ipbmenu ul ul > li.has-sub > a:before {
    display: none;
  }
  #ipbmenu #menu-button {
    display: block;
    padding: 17px;
    color: #000;
    cursor: pointer;
    font-size: 0px;
  }
  #ipbmenu #menu-button:after {
    position: absolute;
    top: 22px;
    right: 17px;
    display: block;
    height: 4px;
    width: 20px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    content: '';
  }
  #ipbmenu #menu-button:before {
    position: absolute;
    top: 16px;
    right: 17px;
    display: block;
    height: 2px;
    width: 20px;
    background: #000;
    content: '';
  }
  #ipbmenu #menu-button.menu-opened:after {
    top: 23px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #000;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #ipbmenu #menu-button.menu-opened:before {
    top: 23px;
    background: #000;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #ipbmenu .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120, 120, 120, 0.2);
    height: 40px;
    width: 46px;
    cursor: pointer;
  }
  #ipbmenu .submenu-button.submenu-opened {
    background: #262626;
  }
  #ipbmenu ul ul .submenu-button {
    height: 34px;
    width: 34px;
  }
  #ipbmenu .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: transparent;
    content: '';
  }
  #ipbmenu ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }
  #ipbmenu .submenu-button.submenu-opened:after {
    background: #ffffff;
  }
  #ipbmenu .submenu-button:before {
    position: absolute;
    top: 10px;
    right: 28px;
    display: block;
    width: 2px;
    height: 8px;
    background: transparent;
    content: '+';
  }

  #ipbmenu .submenu-button:hover,#ipbmenu .submenu-button:hover {
    color: #FFF;
  }

  #ipbmenu ul ul .submenu-button:before {
    top: 8px;
    right:20px;
  }
  #ipbmenu .submenu-button.submenu-opened:before {
    display: none;
  }
  #ipbmenu ul ul li:hover > a, #ipbmenu ul ul li a:hover {
    color: #888888!important;
  }
}

@media only screen and (max-width: $lg){
  #ipbmenu{
    background: #e2e2e2;
    position: absolute;
    top:.2rem;
    left:0;
    right: 0;
    z-index: auto;
    padding-bottom: 2rem;
    #menu-button{
      width: 50px;
      position: static;
    }
    .oneLewelListMenu{
      top: 2rem;

    }
  }
}

@media only screen and (min-width: $lg){
  .hiddenMenu ul li ul li ul {
    display: none!important;
    li{
      a{
        &:before{
          background:red;
        }
        &:after{
          background:red;
        }
      }
    }
  }

  .hiddenMenu > ul > li > ul > li > a:before{
    display:none!important;
  }
  .hiddenMenu > ul > li > ul > li > a:after{
    display:none!important;
  }
}
